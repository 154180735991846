
import Shell from "@shared/Shell";
import useTransactions from "@hooks/useTransactions";
import useMyDetails from "@hooks/useMyDetails";
import { useEffect, useState, Fragment } from "react";
import log from "loglevel";
import {Spinner} from "@shared/Spinner";
import dateFormat from "dateformat";
import { Transition, Dialog } from '@headlessui/react'
import TransactionsSummary from "@shared/TransactionsSummary";
import {COLUMNS} from "@shared/TransactionTableColumns";
import { ReactComponent as AnimatedSpinner } from '@sharedassets/three_dots_animared_spinner.svg';


type Payment = {
  amount: number;
  end: string;
  start: string;
};

export function PaymentHistory() {

  const {isLoading, data, refetch} = useMyDetails();
  const [paymentsListed, setPaymentsListed] = useState(false);

  useEffect(() => {

    if (!isLoading) {
      log.debug("payment history:", data.allPaymentDateCalculations);

      if (data.allPaymentDateCalculations) {
        if (data.allPaymentDateCalculations.length > 0) setPaymentsListed(true);
      }
    }
  
    return () => {
    };
  }, [isLoading, data]);

  return (
    <Shell pageIndex={1}>
      <div>
        {
          isLoading ? 
          <div className="grid justify-items-center">
            <h1 className="text-2xl font-bold">Loading your details...</h1>
            <div className="bg-white text-slate-500 h-32 w-32">
              <Spinner spin={true}/>
            </div> 
          </div>:
          <>
            {
            (paymentsListed) ?
            <PaymentHistoryView payments={data.allPaymentDateCalculations}/> :
            <div>
              No payments recorded in the new system yet. They will appear when you get paid next.
            </div>
            }
          </>
        }     
      </div>
    </Shell>
  );
}

type PaymentHistoryViewProps = {
  payments: Payment[];
};

const PaymentHistoryView = ({ payments}: PaymentHistoryViewProps) => {
  
  const [showTransactionsDlg, setShowTransactionsDlg] = useState(false);
  const [payment, setPayment] = useState<Payment | undefined>();

  function openDlg(selectedPayment: Payment) {

    setPayment(selectedPayment);
    setShowTransactionsDlg(true);
  }

  function closeTransactionsDlg() {
    setShowTransactionsDlg(false);
  }

  return (
    <div>
      {
        (payment) ? <PaymentsDlg show={showTransactionsDlg} hide={closeTransactionsDlg} payment={payment} /> : <></>
      }

      <div className="text-left">
        <p>Sales are calculated from the CLOSE of business from the start date, to the CLOSE of business of the end date.</p>
        <p className="mt-2 mb-4">Click on the payment to view and print the sales for this period.</p>
      </div>
      <div className="grid grid-cols-1 m-4">
      {
        payments.map((item) => (
          <button
              key={item.end}
              onClick={() => {openDlg(item)}}
            >
            <PaymentView key={item.end} payment={item} />
          </button>
        ))
      }
      </div>
    </div>
  );
}


type PaymentViewProps = {
  payment: Payment;
};

const PaymentView = ({ payment }: PaymentViewProps) => {

  return (
    <div className="m-2">

        <div className="overflow-hidden rounded-lg bg-slate-100 px-4 py-5 shadow sm:p-6">
          <dt className="truncate text-sm font-medium text-gray-500">{dateFormat(payment.start, "mmmm dS")} to {dateFormat(payment.end, "mmmm dS yyyy")}</dt>
          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">£{payment.amount}</dd>
        </div>
    </div>
  );
}

type PaymentsDlgProps = {
  show: boolean;
  hide: () => void;
  payment: Payment;

};

const PaymentsDlg = ({show, hide, payment}: PaymentsDlgProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const [columns] = useState([COLUMNS[1], COLUMNS[2], COLUMNS[4], COLUMNS[3], COLUMNS[5]]);
  const {isLoading, data, refetch} = useTransactions(payment.start, payment.end);
  //const [date] = useState(dateFormat(lastPayment, "mmmm dS"));
  
  useEffect(() => {
    
    setOpen(show);
    return () => {
    };
  }, [show]);  

  useEffect(() => {
    
    if (show) {
      setTitle("Sales between " + dateFormat(payment.start, "mmmm dS") + " and " + dateFormat(payment.end, "mmmm dS yyyy") );
    }

    return () => {
    };
  }, [show, payment.start, payment.end]);  

  return (
    <div className="">
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                {
                  isLoading ?
                  <div className="h-8 w-8"><AnimatedSpinner /></div> :
                  <div className="mt-4"> 
                    <TransactionsSummary transactions={data} showContraNet={true} columns={columns} title={title} showPrintButton={true}/>
                  </div>

                }
                  <div className="mt-4">

                      <button
                        type="button"
                        className="ml-4 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-100 disabled:border-red-900 border-1 border-emerald-900 p-4 text-emerald-500 shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={hide}
                      >
                        Close
                      </button>
                    </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default PaymentHistory;
