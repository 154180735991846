import { useQuery } from '@tanstack/react-query'
import useAPIService from "@hooks/useAPIService";
import log from "loglevel";
import { processTransactions } from "@shared/Utils";


type TransactionsQueryType = (
  start: string,
  end: string,
) => any;

const useTransactions: TransactionsQueryType = (
  start,
  end
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useAPIService();

  log.debug("getTransactions invoked", start, end);

  return useQuery({
    queryKey:["transactions", start, end], 
    queryFn: async () => 
      apiService.fetch("getTransactions", {start: start, end: end})
      .then((data: any) => {
          return processTransactions(data);
        })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
    staleTime: Infinity,
    refetchInterval: 100000,
    refetchOnWindowFocus: false,
    refetchOnMount: false
    })
}

export default useTransactions;
